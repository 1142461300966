@import 'normalize';
@import 'mixins';

:root {
  --color-accent-100: #0050cc;
  --color-accent-80: #3373d6;
  --color-accent-50: #7fa7e5;
  --color-accent-20: #ccdcf5;
  --color-accent-10: #eef3fc;
  --color-neutral-100: #edf0f3;
  --color-text-100: #333333;
  --color-text-70: #666666;
  --color-text-40: #a5a5a5;
  --shadow-default: 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 6px rgba(52, 61, 68, 0.05),
    0 10px 20px rgba(52, 61, 68, 0.05);
  --shadow-with-border: 0 0 1px rgba(0, 0, 0, 0.3), 0 2px 6px rgba(52, 61, 68, 0.06),
    0 10px 20px rgba(52, 61, 68, 0.06);
}

@font-face {
  font-family: 'Dela Gothic One';
  src:
    local('Dela Gothic One'),
    local('DelaGothicOne-Regular'),
    url('/fonts/DelaGothicOne/DelaGothicOne-Regular.woff2') format('woff2'),
    url('/fonts/DelaGothicOne/DelaGothicOne-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat Thin'),
    local('Montserrat-Thin'),
    url('/fonts/Montserrat/Montserrat-Thin.woff2') format('woff2'),
    url('/fonts/Montserrat/Montserrat-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat ExtraLight'),
    local('Montserrat-ExtraLight'),
    url('/fonts/Montserrat/Montserrat-ExtraLight.woff2') format('woff2'),
    url('/fonts/Montserrat/Montserrat-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat Light'),
    local('Montserrat-Light'),
    url('/fonts/Montserrat/Montserrat-Light.woff2') format('woff2'),
    url('/fonts/Montserrat/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat'),
    local('Montserrat-Regular'),
    url('/fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
    url('/fonts/Montserrat/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat Medium'),
    local('Montserrat-Medium'),
    url('/fonts/Montserrat/Montserrat-Medium.woff2') format('woff2'),
    url('/fonts/Montserrat/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat SemiBold'),
    local('Montserrat-SemiBold'),
    url('/fonts/Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
    url('/fonts/Montserrat/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat Bold'),
    local('Montserrat-Bold'),
    url('/fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
    url('/fonts/Montserrat/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat ExtraBold'),
    local('Montserrat-ExtraBold'),
    url('/fonts/Montserrat/Montserrat-ExtraBold.woff2') format('woff2'),
    url('/fonts/Montserrat/Montserrat-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat Black'),
    local('Montserrat-Black'),
    url('/fonts/Montserrat/Montserrat-Black.woff2') format('woff2'),
    url('/fonts/Montserrat/Montserrat-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

html {
  font-family: 'Montserrat', sans-serif;
  color: var(--color-text-100);
  scroll-behavior: smooth;
  text-size-adjust: none;
  font-weight: 500;
}
html,
body {
  width: 100%;
  height: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 12px 0 32px 0;
  font-weight: 800;

  &.clear {
    margin: 0 !important;
  }
}
h1,
h2,
h3 {
  font-size: 38px;
}
h4 {
  font-size: 31px;
}
h5 {
  font-size: 25px;
}
h6 {
  font-size: 20px;
}
a {
  color: var(--color-accent-100);
  text-decoration: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    color: var(--color-accent-100);
  }

  &:active {
    color: var(--color-accent-100);
  }
}

@media (max-width: 992px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 18px 0;
  }
  h1,
  h2,
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 21px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 16px;
  }
}

header {
  z-index: 20;
  background-color: white;
  border-bottom: 1px solid var(--color-neutral-100);

  .preheader {
    color: white;
    background-color: var(--color-accent-100);
    z-index: 100;
    position: relative;

    .inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.65rem;
      font-weight: 500;
      height: 25px;
      padding: 0 18px;
      max-width: 1200px;
      margin: auto;
      transition: height 200ms;

      button {
        display: flex;
        align-items: center;
        height: 100%;
        cursor: pointer;
        outline: none;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        color: white;
        font-weight: 500;

        svg {
          margin-right: 6px;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      @media (max-width: 992px) {
        padding: 0 12px;
      }
    }

    @media (max-width: 992px) {
      display: none;
    }
  }
  .header {
    display: flex;
    align-items: center;
    padding: 0 18px;
    height: 50px;
    margin: auto;
    font-size: 0.8rem;
    max-width: 1200px;

    @media (max-width: 992px) {
      padding: 0 0 0 12px;
      overflow: auto;
      height: 45px;
    }

    .logo {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      max-width: 210px;
      flex-shrink: 0;
      cursor: pointer;
      color: var(--color-accent-100);
      margin-right: 12px;
      z-index: 100;
    }
    .menu {
      display: flex;

      .link {
        padding: 0 6px;
        height: 100%;
        display: flex;
        align-items: center;
        color: inherit;
        text-decoration: none;
        box-sizing: border-box;
        white-space: nowrap;
        font-weight: 600;

        span {
          margin-left: 4px;
          padding: 6px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--color-neutral-100);
          line-height: 1;
          font-size: 0.65rem;

          &.yellow {
            background-color: #ffde5a;
            color: #333333;
          }
          &.red {
            background-color: #fb5f5f;
            color: white;
          }
          &.green {
            background-color: #52bf41;
            color: white;
          }
        }

        &.active {
          color: var(--color-accent-100);
        }

        @media (min-width: 992px) {
          &:hover {
            color: var(--color-accent-100) !important;
          }
        }
      }

      &.mobile {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        z-index: 50;
        flex-direction: column;
        text-align: left;
        overflow: auto;
        padding-top: 53px;

        .link {
          height: auto;
          font-size: 1.2rem;
          padding: 6px;
          outline: none;
          border: none;
          outline: none;
          margin: 0 8px 4px 8px;
          background: none;

          &.column {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 0;
          }

          &:last-child {
            margin: 0 8px;
          }

          span {
            padding: 5px 8px 4px 8px;
            border-radius: 6px;
            font-size: 0.8rem;
            margin-left: 8px;
          }

          &:nth-child(1) {
            margin-top: auto;
          }
        }
        .dropdown {
          width: 100%;
          padding: 10px 0;

          .link {
            font-size: 1rem;
          }
        }
        .location {
          padding: 8px 8px 4px 8px;
          margin-top: auto;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            margin-left: 8px;
            color: var(--color-text-70);
            font-size: 16px;
            font-weight: 500;
          }
        }
        .contact {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin: 8px;
          padding: 18px 24px;
          background-color: var(--color-accent-100);
          color: white;
          border-radius: 12px;
          font-size: 1.5rem;
          font-weight: 600;
          height: max-content;
          text-align: center;
          justify-content: center;

          .description {
            font-size: 0.9rem;
            opacity: 0.7;
            margin-top: 4px;
            font-weight: 500;
          }
        }
      }
      &:not(.mobile) {
        @media (max-width: 992px) {
          display: none;
        }
      }

      @media (min-width: 992px) {
        margin: auto;
        height: 100%;
      }
    }
    .button {
      display: none;
      cursor: pointer;
      outline: none;
      color: var(--color-accent-100);
      align-items: center;
      justify-content: flex-end;
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      svg {
        height: 45px;
        width: 45px;

        .line {
          fill: none;
          stroke: var(--color-text-100);
          stroke-width: 3;
          stroke-dasharray: 33 101;
          stroke-dashoffset: 0;
          transition:
            stroke-dasharray 400ms cubic-bezier(0.4, 0, 0.2, 1),
            stroke-dashoffset 400ms cubic-bezier(0.4, 0, 0.2, 1);
        }
      }

      &.active {
        svg {
          .line {
            stroke-dasharray: 101 101;
            stroke-dashoffset: -66.4;
          }
        }
      }

      @media (max-width: 992px) {
        display: flex;
      }
    }
    .liked {
      position: relative;
      display: none;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      color: inherit;

      span {
        position: absolute;
        top: 5px;
        right: -9px;
        border-radius: 50%;
        font-size: 9px;
        width: 22px;
        height: 22px;
        background-color: var(--color-accent-100);
        border: 2px solid #fff;
        color: white;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;

        @media (max-width: 992px) {
          right: auto;
          left: -9px;
        }
      }
      svg {
        transition: fill 200ms;
      }

      @media (min-width: 992px) {
        display: flex;
        height: 100%;
        transition: all 200ms;

        &:hover {
          color: #fb5f5f;

          svg {
            fill: #fb5f5f;
          }
        }
        &:active {
          transform: scale(0.95);
        }
      }
    }
    .actions {
      display: flex;
      flex: 1;
      z-index: 100;
      position: relative;
      background-color: #ffffff;

      .liked {
        display: flex;
        margin-left: auto;
      }

      @media (min-width: 992px) {
        display: none;
      }
    }
  }
  .aftheader {
    position: relative;
  }

  @media (max-width: 992px) {
    position: sticky;
    top: 0;
    z-index: 100;
  }
}
footer {
  .footer {
    margin: 24px auto 0 auto;
    padding: 0 24px;
    box-sizing: border-box;

    .top,
    .middle,
    .seo,
    .apps,
    .bottom {
      position: relative;
      max-width: 1200px;
      margin: auto;
    }

    .top {
      display: grid;
      grid-gap: 18px;
      grid-template-columns: 220px 1fr 220px;
      grid-template-areas: 'lg sc ph';

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-areas:
          'lg'
          'ph'
          'sc';
      }

      .logo {
        cursor: pointer;
        flex-shrink: 0;
        color: var(--color-accent-100);
        grid-area: lg;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 210px;

        svg {
          margin-top: 6px;
        }

        @media (max-width: 768px) {
          margin: auto;
        }
      }
      .socials {
        margin: auto;
        font-size: 0;
        grid-area: sc;

        a {
          @include button-blue;

          display: inline-flex;
          width: 46px;
          height: 46px;
          flex-shrink: 0;
          border-radius: 12px;
          margin: 0 4px;
          background-position: center;
          background-repeat: no-repeat;

          &.vkontakte {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='25' viewBox='0 0 18 11' fill='none'%3E%3Cpath d='M9.82001 10.5C4.40001 10.5 1.12001 6.74 0.990005 0.5H3.73001C3.82001 5.09 5.90001 7.03 7.50001 7.43V0.5H10.13V4.46C11.67 4.29 13.29 2.49 13.83 0.5H16.42C16.2208 1.52117 15.8146 2.49073 15.2264 3.34893C14.6382 4.20713 13.8805 4.93579 13 5.49C13.9817 5.99807 14.8459 6.7065 15.5366 7.5695C16.2274 8.43249 16.7293 9.43084 17.01 10.5H14.17C13.57 8.6 12.1 7.13 10.12 6.93V10.5H9.82001Z' fill='white'/%3E%3C/svg%3E");
          }
          &.odnoklassniki {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24' viewBox='0 0 14 24' fill='none'%3E%3Cpath d='M0.206274 12.8055C-0.365941 14.0186 0.283752 14.5985 1.7661 15.5892C3.02628 16.4289 4.76719 16.736 5.88548 16.8588C5.42715 17.334 7.52745 15.1553 1.47859 21.4306C0.196006 22.7565 2.26083 24.8839 3.54248 23.5861L7.01218 19.9768C8.3405 21.3551 9.61375 22.676 10.4819 23.5912C11.7645 24.8939 13.8283 22.7847 12.5598 21.4356C12.4646 21.338 7.8579 16.5718 8.13887 16.8638C9.27116 16.741 10.9859 16.4158 12.2312 15.5943L12.2303 15.5933C13.7126 14.5975 14.3623 14.0186 13.7985 12.8055C13.4578 12.1168 12.5392 11.5409 11.3164 12.5366C11.3164 12.5366 9.66509 13.9008 7.00191 13.9008C4.3378 13.9008 2.68743 12.5366 2.68743 12.5366C1.46552 11.5359 0.543255 12.1168 0.206274 12.8055Z' fill='white'/%3E%3Cpath d='M7.00047 12C10.3032 12 13 9.31379 13 6.00692C13 2.68621 10.3032 0 7.00047 0C3.69684 0 1 2.68621 1 6.00692C1 9.31379 3.69684 12 7.00047 12ZM7.00047 3.04299C8.62333 3.04299 9.94706 4.36831 9.94706 6.00692C9.94706 7.63169 8.62333 8.95701 7.00047 8.95701C5.37762 8.95701 4.05389 7.63169 4.05389 6.00692C4.05294 4.36732 5.37667 3.04299 7.00047 3.04299Z' fill='white'/%3E%3C/svg%3E");
          }
          &.telegram {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 50 50' fill='%23ffffff'%3E%3Cpath d='M46.137,6.552c-0.75-0.636-1.928-0.727-3.146-0.238l-0.002,0C41.708,6.828,6.728,21.832,5.304,22.445 c-0.259,0.09-2.521,0.934-2.288,2.814c0.208,1.695,2.026,2.397,2.248,2.478l8.893,3.045c0.59,1.964,2.765,9.21,3.246,10.758 c0.3,0.965,0.789,2.233,1.646,2.494c0.752,0.29,1.5,0.025,1.984-0.355l5.437-5.043l8.777,6.845l0.209,0.125 c0.596,0.264,1.167,0.396,1.712,0.396c0.421,0,0.825-0.079,1.211-0.237c1.315-0.54,1.841-1.793,1.896-1.935l6.556-34.077 C47.231,7.933,46.675,7.007,46.137,6.552z M22,32l-3,8l-3-10l23-17L22,32z'/%3E%3C/svg%3E");
          }
        }
      }
      .phone {
        width: 220px;
        text-align: right;
        grid-area: ph;
        margin-left: auto;

        .number {
          font-size: 25px;
          font-weight: 600;
          color: inherit;
          text-decoration: none;
          transition: color 300ms;

          &:hover {
            color: var(--color-accent-100);
          }
        }
        .time {
          font-size: 14px;
          color: var(--color-text-70);

          @media (max-width: 768px) {
            display: none;
          }
        }

        @media (max-width: 768px) {
          margin: auto;
          text-align: center;
        }
      }
    }

    .middle {
      column-count: 4;
      list-style: none;
      margin: 24px auto 0 auto;
      padding: 0;
      column-gap: 24px;

      @media (max-width: 992px) {
        column-count: 3;
      }
      @media (max-width: 768px) {
        display: none;
      }

      li {
        &:not(:last-child) {
          margin-bottom: 12px;
        }

        a {
          cursor: pointer;
          text-decoration: none;
          color: inherit;

          &:hover {
            transition: color 200ms;
            color: var(--color-accent-100);
          }
        }
      }
    }

    .seo {
      margin-top: 18px;

      @media (max-width: 768px) {
        text-align: center;
      }

      h1 {
        font-weight: 400;
        font-size: 0.8rem;
        margin: 0;
        color: var(--color-text-40);
      }
    }

    .apps {
      margin-top: 18px;

      a:first-child {
        margin-right: 4px;
      }

      @media (max-width: 768px) {
        text-align: center;
      }
    }

    .bottom {
      display: flex;
      justify-content: center;
      padding: 24px 0 12px 0;

      span {
        padding-left: 12px;
        color: var(--color-text-70);
      }
    }
  }
}

.block {
  padding: 24px;
  box-sizing: border-box;

  .fixed {
    position: relative;
    max-width: 1200px;
    margin: auto;
    box-sizing: border-box;

    &.columns {
      display: flex;

      @media (max-width: 992px) {
        flex-direction: column;
      }
    }
  }

  &.big {
    padding: 32px 24px;
  }
  &.fill {
    background-color: var(--color-accent-100);
  }
  &.clear {
    padding: 0;
  }
  &.crop {
    overflow: hidden;
  }
  &.zero {
    padding: 0;
    overflow: hidden;
    height: 0;
  }

  @media (max-width: 992px) {
    padding: 12px;

    &.big {
      padding: 24px 12px;
    }
    &.clear {
      padding: 0;
    }
  }
}
.locations {
  display: flex;
  flex-direction: column;
  align-items: center;

  .location {
    display: flex;
    align-items: flex-end;
    color: inherit;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 1.2rem;
    padding: 14px 12px 14px 18px;
    border-radius: 8px;
    width: max-content;
    font-weight: 500;
    transition: all 200ms;

    svg {
      margin-left: 12px;
    }

    &.active {
      color: white;
      background-color: var(--color-accent-100);
    }

    &:not(.active):hover {
      color: var(--color-accent-100);
    }
    &:not(.active):active {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

ymaps[class*='float-button'] {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  border-radius: 12px;
  min-height: 46px !important;
  min-width: 46px !important;
  border: none;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9rem;
  transition: all 200ms;

  &:active {
    transform: scale(0.95);
  }

  &[class*='checked'] {
    background-color: var(--color-accent-100);
    color: white;

    &:active {
      background-color: var(--color-accent-100);
    }
  }

  ymaps[class*='icon_icon_geolocation'] {
    position: relative;
    left: -1px;
    top: 1px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolygon points='3 11 22 2 13 21 11 13 3 11'/%3E%3C/svg%3E");
  }
  ymaps[class*='icon_icon_expand'] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='15 3 21 3 21 9'/%3E%3Cpolyline points='9 21 3 21 3 15'/%3E%3Cline x1='21' y1='3' x2='14' y2='10'/%3E%3Cline x1='3' y1='21' x2='10' y2='14'/%3E%3C/svg%3E");
  }
  ymaps[class*='icon_icon_collapse'] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='4 14 10 14 10 20'/%3E%3Cpolyline points='20 10 14 10 14 4'/%3E%3Cline x1='14' y1='10' x2='21' y2='3'/%3E%3Cline x1='3' y1='21' x2='10' y2='14'/%3E%3C/svg%3E");
  }
}
ymaps[class*='zoom'] {
  width: 46px;
  height: 92px !important;
  padding: 0;

  ymaps[class*='zoom__plus'] {
    border-radius: 12px 12px 0 0;
  }
  ymaps[class*='zoom__minus'] {
    border-radius: 0 0 12px 12px;
  }

  ymaps[class*='zoom__plus'],
  ymaps[class*='zoom__minus'] {
    height: 46px !important;
    border: none;
  }
  ymaps[class*='zoom__icon'] {
    height: 46px !important;
    border: none;
    box-shadow: none;
  }

  ymaps[class*='zoom__plus'] {
    ymaps[class*='zoom__icon'] {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='12' y1='5' x2='12' y2='19'/%3E%3Cline x1='5' y1='12' x2='19' y2='12'/%3E%3C/svg%3E");
    }
  }
  ymaps[class*='zoom__minus'] {
    ymaps[class*='zoom__icon'] {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='5' y1='12' x2='19' y2='12'/%3E%3C/svg%3E");
    }
  }
}
ymaps .point {
  &.default {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    background-color: #0050cc;
    border: 1.5px solid white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    font-weight: 500;
    font-size: 0.7rem;
    box-sizing: border-box;
    color: white;
    flex-shrink: 0;
  }
  &.cluster {
    width: 24px;
    height: 24px;
    background-color: #000;

    .information {
      bottom: 22px;
    }
  }
  &.pop {
    width: 40px;
    height: 40px;
    background-color: #333333;
    color: white;
    animation: pop 200ms ease-out both;

    .information {
      bottom: 38px;
    }

    @keyframes pop {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }
  }
  &.active {
    background-color: #fb5f5f;
    opacity: 1 !important;
  }
  &.single {
    color: var(--color-accent-100);
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 0.8rem;
    box-sizing: border-box;

    svg {
      flex-shrink: 0;
    }
  }

  .information {
    position: absolute;
    font-size: 14px;
    bottom: 15px;
    background-color: white;
    color: var(--color-text-100);
    border-radius: 5px;
    padding: 6px 8px;
    line-height: 1;
    box-shadow: var(--shadow-with-border);
    white-space: nowrap;
    text-align: center;

    .description {
      font-size: 12px;
      color: var(--color-text-40);
    }

    &::after {
      content: '';
      position: absolute;
      left: calc(50% - 4px);
      transform: rotate(45deg);
      width: 8px;
      height: 8px;
      bottom: -2px;
      border-radius: 2px;
      background-color: white;
    }
  }
  @supports (-webkit-touch-callout: none) {
    .count {
      position: relative;
      top: 1px;
    }
  }
}
