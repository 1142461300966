@mixin button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  user-select: none;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 0;
  padding: 0 18px;
  appearance: none;
  font-weight: 500;
  line-height: inherit;
  transition:
    background-color 200ms,
    color 200ms,
    border 200ms,
    opacity 200ms,
    transform 100ms;

  &:not(.no-animation):active {
    opacity: 0.85;
    transform: scale(0.985) translateY(1px);
  }

  &.no-border {
    border-radius: 0;
  }
  &.fullWidth {
    width: 100% !important;
  }
}

@mixin button-blue {
  @include button;

  background-color: var(--color-accent-100);
  border: 1px solid var(--color-accent-100);
  color: white !important;

  &:hover {
    background-color: var(--color-accent-80);
    border-color: var(--color-accent-80);
  }
  &:active {
    background-color: var(--color-accent-100);
    border-color: var(--color-accent-100);
  }
  &:focus {
    border-color: var(--color-accent-100);
  }
}
@mixin button-white {
  @include button;

  background-color: white;
  border: 1px solid white;
  color: var(--color-text-100) !important;

  &:hover,
  &:active {
    background-color: #f0f2f3;
    border-color: #f0f2f3;
  }
  &:focus {
    border-color: #c2c4c5;
  }
}
@mixin button-black {
  @include button;

  background-color: #333333;
  border: 1px solid #333333;
  color: white !important;

  &:hover {
    background-color: #525252;
    border-color: #525252;
  }
  &:active {
    background-color: #616161;
    border-color: #616161;
  }
  &:focus {
    border-color: #5f5f5f;
  }
}
@mixin button-gray {
  @include button;

  background-color: #e4e4e4;
  border: 1px solid #e4e4e4;
  color: var(--color-text-100) !important;

  &:hover {
    background-color: #cecece;
    border-color: #cecece;
  }
  &:active {
    background-color: #bebebe;
    border-color: #bebebe;
  }
  &:focus {
    border-color: #bebebe;
  }
}
@mixin button-green {
  @include button;

  background-color: #52bf41;
  border: 1px solid #52bf41;
  color: white !important;

  &:hover {
    background-color: #4ab339;
    border-color: #4ab339;
  }
  &:active {
    background-color: #389f27;
    border-color: #389f27;
  }
  &:focus {
    border-color: #389f27;
  }
}
@mixin button-red {
  @include button;

  background-color: #fb5f5f;
  border: 1px solid #fb5f5f;
  color: white !important;

  &:hover {
    background-color: #d36464;
    border-color: #d36464;
  }
  &:active {
    background-color: #c45e5e;
    border-color: #c45e5e;
  }
  &:focus {
    border-color: #c45e5e;
  }
}
@mixin button-yellow {
  @include button;

  background-color: #ffde5a;
  border: 1px solid #ffde5a;
  color: var(--color-text-100) !important;

  &:hover {
    background-color: #f3d972;
    border-color: #f3d972;
  }
  &:active {
    background-color: #e6cc64;
    border-color: #e6cc64;
  }
  &:focus {
    border-color: #e6cc64;
  }
}
@mixin button-purple {
  @include button;

  background-color: #865aff;
  border: 1px solid #865aff;
  color: var(--color-text-100) !important;

  &:hover {
    background-color: #704ad8;
    border-color: #704ad8;
  }
  &:active {
    background-color: #5d3eb3;
    border-color: #5d3eb3;
  }
  &:focus {
    border-color: #5d3eb3;
  }
}
